<template>
    <v-dialog v-model="dialog" width="900" persistent scrollable>
        <v-card :disabled="load">
            <v-card-title class="pb-2">
                Cargar incidente
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="pt-6">
                <v-form ref="form">
                    <v-row>
                        <v-col cols="6" sm="3" md="2" class="py-0">
                            <v-autocomplete v-model="empresa" label="Empresa" item-text="nombre" return-object
                                :items="familias" :rules="[rules.required]" validate-on-blur
                                autofocus outlined dense></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="3" class="py-0">
                            <v-autocomplete v-model="sucursal" label="Sucursal" item-text="provincia"
                                item-value="sucursal_ora" :items="sucursales.filter(sucursal => sucursal.familia_id == empresa?.id)" :rules="[rules.required]"
                                validate-on-blur outlined dense @change="local = null"></v-autocomplete>
                        </v-col>
                        <v-col :cols="cargaInc ? 12 : 6" :sm="cargaInc ? 4 : 3" class="py-0">
                            <v-text-field v-if="!cargaInc" v-model="$store.state.username" label="Contacto" outlined
                                readonly filled dense></v-text-field>
                            <v-autocomplete v-else v-model="solicitante" label="Solicitante" item-text="nombre"
                                item-value="usuario"
                                :items="solicitantes.filter(s => s.usuario_id != 70 && s.usuario != 'SALTAAUDIT')"
                                :rules="[rules.required]" validate-on-blur outlined dense></v-autocomplete>
                        </v-col>
                        <v-col cols="6" sm="3" class="py-0">
                            <v-text-field v-model.trim="teamviewer" label="Team Viewer" :rules="[rules.required]"
                                validate-on-blur outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" :md="cargaInc ? 5 : 6" class="py-0">
                            <v-text-field v-model.trim="asunto" label="Asunto" :rules="[rules.required]"
                                validate-on-blur outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <v-textarea v-model="descripcion" rows="3" :rules="[rules.required]" validate-on-blur
                                outlined dense></v-textarea>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <FileDropzone :files="archivos"
                                :tipos="['.txt', '.png', '.jpg', '.webp', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.pdf']"
                                :multiple="true" :max_size="25" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end pb-4">
                <v-btn color="error" class="mr-3" :disabled="load" @click="dialog = false">
                    Cancelar
                </v-btn>
                <BtnConfirmar nombre="Cargar" :texto="`¿Desea cargar el incidente <strong>${asunto}</strong>?`"
                    :loading="load" @action="guardar()" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getBase64 } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue';
import FileDropzone from '../util/FileDropzone.vue';

export default {
    data() {
        return {
            load: false,
            asunto: '',
            teamviewer: '',
            descripcion: '',
            solicitante: '',
            empresa: {
                id: 0
            },
            sucursal: null,
            local: null,
            archivos: [],
            rules: {
                required: value => !!value || 'Campo requerido'
            },
            sucursalesFiltradas: [],
        }
    },
    async created() {
        this.$store.state.loading = true
        this.$store.state.loading = false

        await this.$store.dispatch('genericos/get_sucursales')
        await this.init_form()

        if(this.familias.length === 1){
            this.empresa = this.familias[0]
        }

    },
    props: {
        value: Boolean,
        titulo: {
            type: String,
            default: ''
        },
        personal_id: Number
    },
    computed: {
        ...mapState('incidentes', ['vendedor', 'favorito', 'cargaInc', 'solicitantes']),
        ...mapState('vendedores', ['locales_user']),
        ...mapState('genericos', ['sucursales']),
        ...mapState(['familias']),
        ...mapGetters(['una_empresa']),
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    components: {
        BtnConfirmar,
        FileDropzone
    },
    watch: {
        async dialog(val) {
            if (val) {
                await new Promise(resolve => setTimeout(resolve, 1))
                this.init_form()
            } else {
                this.limpiar()
            }
        }
    },
    methods: {
        async guardar() {
            if (this.$refs.form.validate()) {
                // valido si en algun lado de la descripcion puso adjunto para avisarle por si no adjuntó nada
                const tiene_adjunto = this.descripcion.toLowerCase().indexOf('adjunto') >= 0
                const tiene_archivo = this.descripcion.toLowerCase().indexOf('archivo') >= 0
                if ((tiene_adjunto || tiene_archivo) && this.archivos.length == 0) {
                    let ups_me_olvide_el_archivo = false
                    await this.$swal.fire({
                        icon: 'warning',
                        html: `Se encontró la palabra <strong>${tiene_archivo ? 'archivo' : ''} ${tiene_adjunto ? 'adjunto' : ''}</strong> en el cuerpo del mensaje
              pero no se seleccionó ningún archivo.<div style="font-size: 20px; margin-top: 12px"><strong>¿Desea cargar el incidente de todas formas?</strong></div>`,
                        confirmButtonText: 'Sí, cargar de todas formas',
                        denyButtonText: 'No, seleccionar archivos',
                        denyButtonColor: '#3085d6',
                        showDenyButton: true,
                        focusDeny: true
                    })
                        .then((res) => {
                            // si pone que no o hace click fuera del modal no hace nada
                            if (!res.isConfirmed) {
                                ups_me_olvide_el_archivo = true
                            }
                        })
                    if (ups_me_olvide_el_archivo) return
                }
                this.load = true
                let archivos = []
                // convierto los archivos en base64
                for (const archivo of this.archivos) {
                    await getBase64(archivo).then(data => {
                        archivos.push({
                            file: data.split(',')[1],
                            name: archivo.name
                        })
                    })
                }
                if (!this.personal_id) {
                    this.$store.dispatch('show_snackbar', {
                        text: 'Tu usuario no esta habilitado para cargar incidentes.',
                        color: 'error'
                    })
                    this.load = false
                    return
                }
                await this.$store.dispatch('incidentes/nuevoIncidente', {
                    asunto: this.asunto,
                    teamviewer: this.teamviewer,
                    descripcion: this.descripcion,
                    solicitante: this.personal_id,
                    empresa: this.empresa.empresa_ora,
                    sucursal: this.sucursal,
                    local: this.local,
                    archivos: archivos,
                    personal_id: this.personal_id,
                    origen: 3
                })
                    .then(async (res) => {
                        this.dialog = false
                        await this.$swal.fire({
                            icon: res.errores ? 'warning' : 'success',
                            title: res.message,
                            html: res.errores
                        })
                        this.$emit('actualizar')
                    })
                    .catch(error => {
                        this.$store.dispatch('show_snackbar', {
                            text: error.message,
                            color: 'error'
                        })
                    })
                this.load = false
            }
        },
        async init_form() {
            if (this.titulo) {
                this.asunto = this.titulo
            }

            if (this.familias.length == 1) {
                this.empresa = this.familias[0]
            }

        },

        setSucursales() {
            this.sucursal = null
            if (this.empresa) {
                this.sucursalesFiltradas = this.sucursales.filter(
                    suc => suc.familia_id === this.empresa.id
                )
                if (this.sucursalesFiltradas.length === 1) {
                    this.sucursal = this.sucursalesFiltradas[0].sucursal_ora
                }
            } else {
                this.sucursalesFiltradas = []
            }
        },

        limpiar() {
            this.$refs.form.resetValidation()
            this.asunto = ''
            this.teamviewer = ''
            this.descripcion = ''
            this.solicitante = ''
            this.empresa = null
            this.sucursal = null
            this.local = null
            this.archivos = []
        }
    }
}
</script>