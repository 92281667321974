var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"d-flex justify-center mt-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"readonly":""}},[_c('v-btn',{staticStyle:{"position":"absolute","top":"-15px","right":"55px","z-index":"1"},attrs:{"color":"success","small":"","fab":"","title":"Nuevo Cobro Online"},on:{"click":_vm.abrir_modal}},[_c('v-icon',[_vm._v("fas fa-plus")])],1),_c('v-expansion-panel-header',{staticClass:"py-0 px-4 text-h5",staticStyle:{"cursor":"default"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.panel = _vm.panel === 1 ? 0 : 1}}},[_c('v-icon',[_vm._v("fas fa-chevron-down")])],1)]},proxy:true}])},[_c('div',[_c('v-icon',{staticClass:"mb-1",attrs:{"left":""}},[_vm._v("fas fa-filter")]),_vm._v(" Filtros ")],1)]),_c('v-expansion-panel-content',{staticClass:"pt-1"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.buscar.apply(null, arguments)}}},[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Empresa "),_c('v-autocomplete',{attrs:{"item-text":"nombre","return-object":"","tabindex":"1","items":_vm.familias,"hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.set_locales()}},model:{value:(_vm.filtro.empresa),callback:function ($$v) {_vm.$set(_vm.filtro, "empresa", $$v)},expression:"filtro.empresa"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Sucursal "),_c('v-autocomplete',{attrs:{"item-text":"provincia","item-value":"id","items":_vm.sucursales.filter(
                        (suc) => suc.familia_id == _vm.filtro.empresa.id
                      ),"hide-details":"","outlined":"","dense":"","return-object":""},model:{value:(_vm.filtro.sucursal),callback:function ($$v) {_vm.$set(_vm.filtro, "sucursal", $$v)},expression:"filtro.sucursal"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Estado "),_c('v-autocomplete',{attrs:{"item-text":"estado_nombre","items":_vm.estados,"hide-details":"","outlined":"","dense":"","return-object":""},model:{value:(_vm.filtro.estado),callback:function ($$v) {_vm.$set(_vm.filtro, "estado", $$v)},expression:"filtro.estado"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"6","md":"9","md":typeof _vm.filtro.fecha_desde !== 'string' ? 6 : 4}},[_c('FechaPickerRango',{attrs:{"limpiar":_vm.limpiarFecha},on:{"update:limpiar":function($event){_vm.limpiarFecha=$event}},model:{value:(_vm.filtro.fecha_desde),callback:function ($$v) {_vm.$set(_vm.filtro, "fecha_desde", $$v)},expression:"filtro.fecha_desde"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"3","md":"12"}},[_c('BtnFiltro',{attrs:{"loading":_vm.load},on:{"clear":function($event){return _vm.limpiar()}}})],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"cebra elevation-2 mt-2",attrs:{"headers":_vm.headers,"items":_vm.cobros,"loading":_vm.load,"dense":"","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"d-flex justify-end pa-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('SearchDataTable',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mx-auto mt-4",attrs:{"max-width":"400","type":"warning","border":"left","dense":"","text":""}},[_vm._v(" No hay datos para los filtros seleccionados ")])]},proxy:true},_vm._l((_vm.headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.acciones`,fn:function({ item }){return [(
              (item.estado_id == 2 || item.estado_id == 8) && _vm.permiso == 1
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('BtnConfirmar',_vm._g({attrs:{"icon_button":true,"small":true,"icono":"fas fa-ban","color":"error","title":"Anular Cobro","texto":`¿Esta seguro de Anular el Cobro ${item.pedido_numero}?`},on:{"action":function($event){return _vm.anularCobro(item)}}},on))]}}],null,true)},[_c('span',[_vm._v("Anular Cobro")])]):_vm._e(),(
              item.estado_id == 2 ||
              item.estado_id == 4 ||
              item.estado_id == 8 ||
              item.estado_id == 9
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"success"},on:{"click":function($event){return _vm.descargarCompCobro(item)}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-print")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Comprobante")])]):_vm._e(),(item.estado == 'ERROR' || item.estado == 'PENDIENTE')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"indigo"},on:{"click":function($event){return _vm.reprocesarCobro(item)}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-sync")])],1)]}}],null,true)},[_c('span',[_vm._v("Reprocesar Cobro")])]):_vm._e()]}}],null,true)})],1)],1),_c('PDFViewer',{attrs:{"pdf":_vm.pdf,"nombre":_vm.nombreVer,"titulo":_vm.nombreVer},model:{value:(_vm.dialogPdf),callback:function ($$v) {_vm.dialogPdf=$$v},expression:"dialogPdf"}}),_c('ModalUnCobro',{attrs:{"esNuevoCobro":_vm.esNuevoCobro,"reprocesarCobro":_vm.item_reprocesar},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }