<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Boton Agregar nuevo Cobro Online -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Cobro Online"
              @click="abrir_modal"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>

            <!-- Titulo Filtros -->
            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <!-- Empresa, Sucursal, Pto Vta y Operacion -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.empresa"
                      item-text="nombre"
                      return-object
                      tabindex="1"
                      :items="familias"
                      hide-details
                      outlined
                      dense
                      @change="set_locales()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="filtro.sucursal"
                      item-text="provincia"
                      item-value="id"
                      :items="
                        sucursales.filter(
                          (suc) => suc.familia_id == filtro.empresa.id
                        )
                      "
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <!-- Estado de Cobro, Fecha y Botones -->
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="filtro.estado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="9" class="py-1" :md="typeof filtro.fecha_desde !== 'string' ? 6 : 4">
                    <FechaPickerRango
                      v-model="filtro.fecha_desde"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" md="12" class="pt-0">
                    <BtnFiltro :loading="load" @clear="limpiar()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="cobros"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable v-model="search" />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>

          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>

          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip
              bottom
              v-if="
                (item.estado_id == 2 || item.estado_id == 8) && permiso == 1
              "
            >
              <template v-slot:activator="{ on }">
                <BtnConfirmar
                  :icon_button="true"
                  :small="true"
                  icono="fas fa-ban"
                  @action="anularCobro(item)"
                  color="error"
                  title="Anular Cobro"
                  :texto="`¿Esta seguro de Anular el Cobro ${item.pedido_numero}?`"
                  v-on="on"
                />
              </template>
              <span>Anular Cobro</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                item.estado_id == 2 ||
                item.estado_id == 4 ||
                item.estado_id == 8 ||
                item.estado_id == 9
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  color="success"
                  @click="descargarCompCobro(item)"
                  v-on="on"
                >
                  <v-icon class="mr-2" small>fas fa-print</v-icon>
                </v-btn>
              </template>
              <span>Descargar Comprobante</span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item.estado == 'ERROR' || item.estado == 'PENDIENTE'"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  icon
                  color="indigo"
                  @click="reprocesarCobro(item)"
                  v-on="on"
                >
                  <v-icon class="mr-2" small>fas fa-sync</v-icon>
                </v-btn>
              </template>
              <span>Reprocesar Cobro</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Visualizador PDF -->
    <PDFViewer
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="nombreVer"
      :titulo="nombreVer"
    />
    <ModalUnCobro
      :esNuevoCobro="esNuevoCobro"
      :reprocesarCobro="item_reprocesar"
      v-model="modal"
    />
  </div>
</template>

<script>
import BtnFiltro from "../components/util/BtnFiltro.vue";
import FechaPickerRango from "../components/util/FechaPickerRango.vue";
import { mapState } from "vuex";
import { format_money } from "../util/utils";
import SearchDataTable from "../components/util/SearchDataTable.vue";
import { pdfCobroOnline } from "../util/plantillas/pdfs";
import PDFViewer from "../util/plantillas/PDFViewer.vue";
import BtnConfirmar from "../components/util/BtnConfirmar.vue";
import ModalUnCobro from "../components/cobro-online/ModalUnCobro.vue";
import { get } from "firebase/database";

export default {
  data() {
    return {
      load: false,
      esNuevoCobro: 1,
      panel: 0,
      estados: [],
      permiso: 0,
      modal: false,
      limpiarFecha: false,
      filtro: {
        empresa: {},
        sucursal: null,
        estado: {},
        fecha_desde: "",
        fecha_hasta: "",
      },
      cobros: [],
      headers: [
        { text: "Pedido", align: "center", value: "pedido_numero" },
        { text: "Fecha Cupón", align: "center", value: "fecha_cupon" },
        { text: "Transacción", align: "center", value: "transaccion_id" },
        { text: "Punto de Venta", align: "center", value: "pto_vta" },
        { text: "Titular", align: "center", value: "titular" },
        { text: "DNI", align: "center", value: "documento_numero" },
        { text: "Estado", align: "center", value: "estado" },
        {
          text: "Importe",
          align: "center",
          value: "importe",
          formatter: format_money,
        },
        { text: "Acciones", align: "center", value: "acciones" },
      ],
      search: "",
      dialogPdf: false,
      pdf: "",
      nombreVer: "",
      item_reprocesar: {
        tipo_pedido: null,
        pedido_numero: null,
      },
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    async initForm() {
      this.$store.state.loading = true;
      await this.$store.dispatch("genericos/get_sucursales");
      const getPermisos = await this.$store.dispatch("cobros/getPermisoCobro")

      if(getPermisos.exito == 1){
        this.permiso = getPermisos.permiso
      }
      
      const getOperacion = await this.$store.dispatch("cobros/init_cobros", {
        nuevo: 1,
        id: 0,
        tipo_pedido: 0,
      });

      if (getOperacion.resultado == 1) {
        this.estados = getOperacion.estados;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: operacionesPeticion.msj,
          color: "error",
        });
      }
      this.$store.state.loading = false;
    },

    validarObligatorios() {
      return Object.keys(this.filtro.empresa).length == 0
        ? { valido: false, msj: "Debe seleccionar una empresa" }
        : { valido: true, msj: "OK" };
    },

    crearFiltrosBusqueda() {
      let fecha_desde = "";
      let fecha_hasta = "";
      if (typeof this.filtro.fecha_desde === "string") {
        fecha_desde = this.filtro.fecha_desde;
      } else if (
        typeof this.filtro.fecha_desde === "object" &&
        this.filtro.fecha_desde.fecha_desde
      ) {
        fecha_desde = this.filtro.fecha_desde.fecha_desde;
        fecha_hasta = this.filtro.fecha_desde.fecha_hasta || "";
      } else {
        fecha_desde = "";
        fecha_hasta = "";
      }
      return {
        empresa_id:
          this.filtro.empresa && Object.keys(this.filtro.empresa).length > 0
            ? this.filtro.empresa.empresa_ora
            : 0,
        local_id:
          this.filtro.sucursal && Object.keys(this.filtro.sucursal).length > 0
            ? this.filtro.sucursal.ptovta_ora
            : 0,
        sucursal_id:
          this.filtro.sucursal && Object.keys(this.filtro.sucursal).length > 0
            ? this.filtro.sucursal.sucursal_ora
            : 0,
        operacion_id:
          this.filtro.empresa && Object.keys(this.filtro.empresa).length > 0
            ? this.filtro.empresa.operacion_cobro_ora
            : 0,
        estado_id:
          this.filtro.estado && Object.keys(this.filtro.estado).length > 0
            ? this.filtro.estado.estado_id
            : 0,
        fecha_desde: fecha_desde,
        fecha_hasta: fecha_hasta,
      };
    },

    async buscar() {
      this.cobros = [];
      this.search = "";
      this.load = true;
      let correcto = this.validarObligatorios();
      if (correcto.valido == false) {
        this.load = false;
        this.$store.dispatch("show_snackbar", {
          text: correcto.msj,
          color: "warning",
        });
        return;
      }

      const filtroBuscar = this.crearFiltrosBusqueda();

      let cobrosPeticion = await this.$store.dispatch(
        "cobros/get_cobros_online",
        {
          empresa: filtroBuscar.empresa_id,
          sucursal: filtroBuscar.sucursal_id,
          pto_vta: filtroBuscar.local_id,
          operacion: filtroBuscar.operacion_id,
          estado: filtroBuscar.estado_id,
          fecha_desde: filtroBuscar.fecha_desde,
          fecha_hasta: filtroBuscar.fecha_hasta,
        }
      );
      this.load = false;
      if (cobrosPeticion.resultado == 1) {
        this.cobros = cobrosPeticion.cobros;
      } else {
        this.$store.dispatch("show_snackbar", {
          text: cobrosPeticion.msj,
          color: "error",
        });
      }
    },

    limpiar() {
      this.filtro.empresa = {};
      this.filtro.estado = {};
      this.filtro.fecha = null;
      this.filtro.sucursal = null;
      this.limpiarFecha = true;
    },

    set_locales() {
      let sucursal = this.sucursales.filter(
        (sucursal) => sucursal.familia_id === this.filtro.empresa.id
      );
      this.filtro.sucursal = sucursal.length == 1 ? sucursal[0].id : null;
    },

    async checkCobroSinAsociar(item) {
      this.load = true;
      let checkPeticion = await this.$store.dispatch(
        "cobros/check_cobro_sin_asociar",
        { transaccion_id: item.transaccion_id }
      );
      if (checkPeticion.resultado == 1) {
        // prosigo con la anulación
        let body = {
          transaccion_id: item.transaccion_id,
          empresa_id: item.empresa_codigo,
          importe: "0",
        };
        let anulacionPeticion = await this.$store.dispatch(
          "cobros/anular_cobro_electronico",
          body
        );
        this.load = false;
        if (anulacionPeticion.resultado == 1) {
          this.$store.dispatch("show_snackbar", {
            text: "El Cobro se anuló correctamente.",
            color: "success",
          });
          setTimeout(() => [this.buscar()], 2000);
        } else {
          this.$store.dispatch("show_snackbar", {
            text: anulacionPeticion.msj,
            color: "error",
          });
        }
      } else {
        this.load = false;
        this.$store.dispatch("show_snackbar", {
          text: checkPeticion.msj,
          color: "error",
        });
      }
    },

    async anularCobro(item) {
      // es de decidir?
      if (item.pasarela_pago != 2) {
        this.$store.dispatch("show_snackbar", {
          text: "No se puede anular un Cobro que no se haya echo por DECIDIR por este medio.",
          color: "info",
        });
        return;
      }
      // esta sin asociar?
      if (item.estado_id == 8) {
        // primero voy a ver si puedo anularlo
        this.checkCobroSinAsociar(item);
        return;
      }
      this.$store.state.loading = true;
      let body = {
        transaccion_id: item.transaccion_id,
        empresa_id: item.empresa_codigo,
        importe: "0",
      };
      let anulacionPeticion = await this.$store.dispatch(
        "cobros/anular_cobro_electronico",
        body
      );
      this.$store.state.loading = false;

      if (anulacionPeticion.resultado == 1) {
        this.$store.dispatch("show_snackbar", {
          text: "El Cobro se anuló correctamente.",
          color: "success",
        });
        setTimeout(() => [this.buscar()], 2000);
      } else {
        this.$store.dispatch("show_snackbar", {
          text: anulacionPeticion.msj,
          color: "error",
        });
      }
    },

    async descargarCompCobro(item) {
      this.$store.state.loading = true;
      let cobroPeticion = await this.$store.dispatch(
        "cobros/get_pdf_cobro_data",
        { id: item.id }
      );
      this.$store.state.loading = false;
      if (cobroPeticion.resultado == 1) {
        // pido el pdf
        let pdfPeticion = await pdfCobroOnline(cobroPeticion.cobro);
        if (pdfPeticion.resultado == 1) {
          this.pdf = pdfPeticion.pdf;
          this.nombreVer = "Comprobante del Cobro N°" + item.pedido_numero;
          this.dialogPdf = true;
        } else {
          this.$store.dispatch("show_snackbar", {
            text: pdfPeticion.msj,
            color: "error",
          });
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: cobroPeticion.msj,
          color: "error",
        });
      }
    },

    reprocesarCobro(item) {
      this.item_reprocesar = {
        tipo_pedido: item.tipo_pedido,
        pedido_numero: item.pedido_numero,
      };
      this.esNuevoCobro = 0;
      this.modal = true;
    },

    abrir_modal() {
      this.esNuevoCobro = 1;
      this.modal = true;
    },
  },
  computed: {
    ...mapState(["familias"]),
    ...mapState("genericos", ["sucursales"]),
  },
  components: {
    BtnFiltro,
    FechaPickerRango,
    SearchDataTable,
    PDFViewer,
    BtnConfirmar,
    ModalUnCobro,
  },
};
</script>
