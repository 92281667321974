<template>
    <v-dialog v-model="dialog" width="700" height="auto">
        <v-card>
            <v-card-text class="pt-3 px-3 pb-0">
                <h2 class="my-2">Adjuntar archivos</h2>
                <FileDropzone :files="files"
                    :tipos="['.txt', '.png', '.jpg', '.webp', '.doc', '.docx', '.csv', '.xls', '.xlsx', '.pdf']"
                    :multiple="true" :max_size="25" />
            </v-card-text>
            <v-card-actions class="d-flex justify-end pt-0 pb-4 px-6">
                <v-btn color="secondary" @click="dialog = false">
                    Volver
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileDropzone from '../util/FileDropzone.vue';

export default {
    props: {
        value: Boolean,
        archivos: Array
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        files: {
            get() {
                return this.archivos
            },
            set(value) {
                this.$emit('update:archivos', value)
            }
        }
    },
    components: {
        FileDropzone
    }
}
</script>