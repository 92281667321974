<template>
  <v-row class="pa-0">
    <v-col cols="12" md="4" :md="rango_sel !== 4 ? 12 : 4">
      <span>{{ titulo }}</span>
      <v-autocomplete
        v-model="rango_sel"
        tabindex="1"
        :items="rangos"
        hide-details
        outlined
        dense
      ></v-autocomplete>
    </v-col>
    <v-col
      v-if="rango_sel === 4"
      cols="12"
      md="4"
      :md="rango_sel !== 4 ? 0 : 4"
      class="fecha_picker"
    >
      <span>Desde</span>
      <FechaPicker
        v-model="fecha_rangos.fecha_desde"
        :hideDetails="true"
        :clearable="true"
        :right="right_desde"
        :left="left_desde"
      />
    </v-col>
    <v-col
      v-if="rango_sel === 4"
      cols="12"
      md="3"
      :md="rango_sel !== 4 ? 0 : 4"
      class="fecha_picker"
    >
      <span>Hasta</span>
      <FechaPicker
        v-model="fecha_rangos.fecha_hasta"
        :hideDetails="true"
        :clearable="true"
        :right="right_hasta"
        :left="left_hasta"
      />
    </v-col>
  </v-row>
</template>

<script>
/*
  Devuelve las fechas en formato YYYY-MM-DD
 */
import moment from 'moment'
import FechaPicker from './FechaPicker.vue'

export default {
  data() {
    return {
      menu: false,
      date: null,
      dates_format: '',
      rango_sel: this.rango_def,
      // desde: "",
      fecha_rangos: {
        fecha_desde: null,
        fecha_hasta: null
      },
      rangos: [
        { value: 1, text: 'Hoy' },
        { value: 5, text: 'Última semana' },
        { value: 2, text: 'Último mes' },
        { value: 3, text: 'Últimos 6 meses' },
        { value: 4, text: 'Personalizado' }
      ]
    }
  },
  created() {
    this.set_dates(this.rango_def)
  },
  props: {
    value: {
      type: [String, Object]
    },

    titulo: {
      type: String,
      default: 'Fecha'
    },

    cols: {
      type: Number,
      default: 4
    },
    clearable: {
      type: Boolean,
      default: false
    },

    /**
     *  En el v-model debe enviarse un array con dos valores null => [ null, null ]
     *  correspondientes a la fecha desde y fecha hasta
     *
     *  El componente ya toma al valor de la fehcha en base al
     *  rango por defecto
     */

    // value: String,
    // rango por defecto: Último mes
    rango_def: {
      type: Number,
      default: 2
    },
    // este prop sirve cuando se limpia el filtro desde otra vista, para que se actualice el valor al rango por defecto
    // debe enviase sincronizaco con .sync
    limpiar: {
      type: Boolean,
      default: false
    },

    /**
     * Props para definir la ubicación del date picker cuando el overflow sale de los márgenes
     */
    right_desde: {
      type: Boolean,
      default: true
    },
    left_desde: {
      type: Boolean,
      default: false
    },
    right_hasta: {
      type: Boolean,
      default: true
    },
    left_hasta: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dates: {
      get() {
        return this.value
      },
      set(value) {
        if (
          this.fecha_rangos.fecha_desde == null &&
          this.fecha_rangos.fecha_hasta == null
        ) {
          this.$emit('input', value)
        }
      }
    },

    desde: {
      get() {
        return this.value
      },
      set(value) {
        if (
          this.fecha_rangos.fecha_desde == null &&
          this.fecha_rangos.fecha_hasta == null
        ) {
          this.$emit('input', value)
        }
      }
    },

    limpiar_filtro: {
      get() {
        return this.limpiar
      },
      set(value) {
        this.$emit('update:limpiar', value)
      }
    }
  },

  watch: {
    rango_sel(val) {
      this.set_dates(val)
      if (val != 4) {
        this.fecha_rangos.fecha_desde = null
        this.fecha_rangos.fecha_hasta = null
        this.set_dates(val)
      }
    },

    limpiar_filtro(val) {
      if (val) {
        this.fecha_rangos.fecha_desde = null
        this.fecha_rangos.fecha_hasta = null
        if (this.rango_sel === this.rango_def) {
          this.set_dates(this.rango_def)
        } else {
          this.rango_sel = this.rango_def
        }
        this.limpiar_filtro = false
      }
    },

    fecha_rangos: {
      deep: true,
      handler(value) {
        if (value.fecha_desde != null) {
          this.$emit('input', value)
        }
      }
    },
  },
  methods: {
    set_dates(val) {
      switch (val) {
        case 1:
          this.desde = moment(new Date()).format('DD/MM/YYYY')
          break
        case 2:
          this.desde = moment(new Date())
            .subtract(1, 'months')
            .format('DD/MM/YYYY')
          break
        case 3:
          this.desde = moment(new Date())
            .subtract(6, 'months')
            .format('DD/MM/YYYY')
          break
        case 4:
          this.desde = {}
          break
        case 5:
          this.desde = moment(new Date())
            .subtract(1, 'week')
            .format('DD/MM/YYYY')
          break
      }
    },
  },

  components: { FechaPicker }
}
</script>
