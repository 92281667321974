<template>
    <v-row class="d-flex justify-center mt-0">
        <v-col cols="12">
            <!-- FILTROS -->
            <v-expansion-panels v-model="panel">
                <v-expansion-panel readonly>
                    <v-btn style="position: absolute; top: -15px; right: 55px; z-index: 1" color="success"
                        title="Cargar incidente" small fab @click="dialog = true; titulo = null">
                        <v-icon>fas fa-plus</v-icon>
                    </v-btn>
                    <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
                        <div>
                            <v-icon class="mb-1" left>fas fa-filter</v-icon>
                            Filtro
                        </div>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                                <v-icon>fas fa-chevron-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-1">
                        <v-form @submit.prevent="buscar()">
                            <v-row>
                                <v-col cols="5" sm="2" class="py-1">
                                    Número
                                    <v-text-field v-model.trim="filtro.id" tabindex="1" type="number" hide-details
                                        outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="7" sm="3" xl="2" class="py-1">
                                    Estado
                                    <v-autocomplete v-model="filtro.estado" item-text="nombre" item-value="id"
                                        tabindex="1" :items="estados" hide-details clearable outlined
                                        dense></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="3" md="4" class="py-1">
                                    Asunto
                                    <v-text-field v-model.trim="filtro.asunto" tabindex="1" hide-details outlined
                                        dense></v-text-field>
                                </v-col>
                                <BtnFiltro :loading="load" @clear="limpiar()" />
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <!-- DATA TABLE -->
            <v-data-table v-model="selected" class="cebra elevation-2 mt-2" sort-by="id" :items="incidentes"
                :headers="headers" :loading="load" :search="search" single-select sort-desc dense @click:row="rowClick">
                <!-- busqueda local -->
                <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                        <v-col cols="6" sm="3" xl="2">
                            <SearchDataTable v-model="search" />
                        </v-col>
                    </v-row>
                </template>
                <!--template para formatos personalizados -->
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }">
                    {{ header.formatter(value) }}
                </template>
                <!-- estado colores -->
                <template v-slot:[`item.estado_nombre`]="{ item }">
                    <v-chip
                        :color="estados.find(e => e.id == item.estado) ? estados.find(e => e.id == item.estado).color : ''"
                        small>
                        {{ item.estado_nombre }}
                    </v-chip>
                </template>
                <!-- no data -->
                <template v-slot:no-data>
                    <v-alert class="mx-auto mt-4" max-width="400" type="warning" border="left" dense text>
                        No hay datos para los filtros seleccionados
                    </v-alert>
                </template>
            </v-data-table>
            <!-- VISUALIZACION DE INCIDENTES -->
            <v-expand-transition>
                <section id="detalle">
                    <!-- DETALLE -->
                    <v-card v-if="selected.length > 0" class="my-2">
                        <v-card-title class="d-flex justify-end pb-0 pt-1 pr-1">
                            <v-btn title="Cerrar detalle" icon x-small @click="selected = []">
                                <v-icon>fas fa-times</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-title class="py-2" style="word-break: normal">
                            Incidente Nº {{ selected[0].id }} - {{ selected[0].asunto }}
                            <v-spacer></v-spacer>
                            <v-chip
                                :color="estados.find(e => e.id == selected[0].estado) ? estados.find(e => e.id == selected[0].estado).color : ''">
                                {{ selected[0].estado_nombre }}
                            </v-chip>
                        </v-card-title>
                        <v-col cols="12" class="d-flex mt-n4 pb-0 px-4" style="font-size: 13px;">
                            Cargado por {{ selected[0].usuario_creacion }} el {{
                                moment(selected[0].fecha_creacion).format('DD/MM/YY[a las] HH:mm') }}
                        </v-col>
                        <v-card-text class="pt-1">
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea v-model="selected[0].descripcion" rows="3" hide-details auto-grow
                                        outlined readonly filled dense></v-textarea>
                                </v-col>
                                <v-col
                                    v-if="archivos.filter(a => a.comentario_id == null && a.respuesta != 1).length > 0"
                                    cols="12" class="pt-0">
                                    <v-chip
                                        v-for="(archivo, index) in archivos.filter(a => a.comentario_id == null && a.respuesta != 1)"
                                        class="mb-1 mr-2" :color="get_atributo_x_extension(archivo.extension, 'color')"
                                        :key="index" dark @click="ver_archivo(archivo, '')">
                                        <v-icon class="ml-1" left>{{ get_atributo_x_extension(archivo.extension,
                                            'icono')
                                            }}</v-icon>
                                        {{ archivo.nombre }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <!-- COMENTARIOS -->
                    <v-card v-if="selected.length > 0 && !(comentarios.length == 0 && selected[0].respuesta)">
                        <v-card-title class="py-2" style="word-break: normal">
                            Comentarios
                        </v-card-title>
                        <v-card-text>
                            <v-row v-if="load_comentario">
                                <v-skeleton-loader style="width: 100%;"
                                    type="list-item-avatar-three-line"></v-skeleton-loader>
                                <v-skeleton-loader style="width: 100%;" type="actions"></v-skeleton-loader>
                            </v-row>
                            <v-row v-else>
                                <!-- "chat" -->
                                <v-col v-for="(comentario, index) in comentarios" :key="index" cols="12" class="d-flex">
                                    <!-- icono usuario -->
                                    <v-avatar v-if="comentario.carga != 1 && !$vuetify.breakpoint.xs" color="cyan"
                                        class="mr-3" style="font-size:22px; cursor: default">
                                        <v-icon>fas fa-user</v-icon>
                                    </v-avatar>
                                    <v-card style="width: 100%;">
                                        <v-card-text class="d-flex pb-1 pt-2 px-3">
                                            <span class="font-weight-bold body-1">
                                                {{ comentario.usuario }}
                                            </span>
                                            <v-spacer></v-spacer>
                                            <span class="text-right">
                                                {{ comentario.fecha }}
                                            </span>
                                        </v-card-text>
                                        <!-- comentario -->
                                        <v-card-text class="d-flex py-0 pb-1 px-3 body-1">
                                            {{ comentario.comentario }}
                                        </v-card-text>
                                        <!-- archivos -->
                                        <v-card-text class="py-1 px-3">
                                            <v-chip
                                                v-for="(archivo, index) in archivos.filter(a => a.comentario_id == comentario.id)"
                                                class="mb-1 mr-2"
                                                :color="get_atributo_x_extension(archivo.extension, 'color')"
                                                :key="index" small dark
                                                @click="ver_archivo(archivo, `/${comentario.id}`)">
                                                <v-icon class="ml-1" small left>{{
                                                    get_atributo_x_extension(archivo.extension,
                                                        'icono') }}</v-icon>
                                                {{ archivo.nombre }}
                                            </v-chip>
                                        </v-card-text>
                                    </v-card>
                                    <!-- icono admin -->
                                    <v-avatar v-if="comentario.carga == 1 && !$vuetify.breakpoint.xs"
                                        :color="$vuetify.theme.dark ? 'lime darken-1' : 'lime'" class="ml-3"
                                        style="font-size:22px; cursor: default">
                                        <v-icon>fas fa-desktop</v-icon>
                                    </v-avatar>
                                </v-col>
                                <!-- enviar comentario -->
                                <template v-if="!selected[0].respuesta">
                                    <v-col cols="12" sm="10">
                                        <v-textarea v-model.trim="comentario" rows="2" hide-details outlined
                                            dense></v-textarea>
                                        <div v-if="archivos_adjuntos.length > 0" class="mt-1">
                                            <strong>
                                                {{ archivos_adjuntos.length }} archivo{{ archivos_adjuntos.length == 1 ?
                                                    '' : 's' }} adjunto{{ archivos_adjuntos.length == 1 ? '' : 's' }}:
                                            </strong>
                                            {{ archivos_adjuntos.map(a => a.name).join(', ') }}
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="2" class="d-flex justify-end align-center">
                                        <v-btn color="info" class="mr-3" title="Adjuntar archivos" small fab
                                            @click="dialog_archivos = true">
                                            <v-icon>fas fa-paperclip</v-icon>
                                        </v-btn>
                                        <v-btn color="success" class="mr-0 mr-md-3" title="Enviar comentario" small fab
                                            @click="enviar_comentario()">
                                            <v-icon class="mr-1">fas fa-paper-plane</v-icon>
                                        </v-btn>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <!-- RESPUESTA -->
                    <v-card v-if="selected.length > 0 && selected[0].respuesta" class="mt-2">
                        <v-card-title class="pt-2 pb-0" style="word-break: normal">
                            Respuesta
                        </v-card-title>
                        <div class="body-1 mx-4">
                            Finalizado por {{ selected[0].usuario_finalizacion }} el {{
                                moment(selected[0].fecha_finalizacion).format('DD/MM/YY [a las] HH:mm') }}
                        </div>
                        <v-card-text class="pt-2">
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea v-model="selected[0].respuesta" rows="3" hide-details auto-grow outlined
                                        readonly filled dense></v-textarea>
                                </v-col>
                                <v-col
                                    v-if="archivos.filter(a => a.comentario_id == null && a.respuesta == 1).length > 0"
                                    cols="12" class="pt-0">
                                    <v-chip
                                        v-for="(archivo, index) in archivos.filter(a => a.comentario_id == null && a.respuesta == 1)"
                                        class="mb-1 mr-2" :color="get_atributo_x_extension(archivo.extension, 'color')"
                                        :key="index" dark @click="ver_archivo(archivo, '/respuesta')">
                                        <v-icon class="ml-1" left>{{ get_atributo_x_extension(archivo.extension,
                                            'icono')
                                            }}</v-icon>
                                        {{ archivo.nombre }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="d-flex pt-0 pb-3 justify-end mx-2">
                            <v-btn color="warning"
                                @click="dialog = true; titulo = `Reabierto Nº${selected[0].id} - ${selected[0].asunto}`">
                                <v-icon left>far fa-life-ring</v-icon>
                                Reabrir incidente
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </section>
            </v-expand-transition>
        </v-col>
        <!-- COMPONENTES -->
        <NuevoIncidente v-model="dialog" :titulo="titulo" @actualizar="buscar()" :personal_id="personal_id" />
        <PDFViewer v-model="dialog_pdf" :pdf="pdf" :nombre="nombre_ver" :titulo="nombre_ver" />
        <ImgViewer v-model="dialog_img" :img="img" :nombre="nombre_ver" />
        <AdjuntarArchivos v-model="dialog_archivos" :archivos.sync="archivos_adjuntos" />
    </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { format_date, tipos_archivos, getBase64 } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro.vue';
import FechaPicker from '../../components/util/FechaPicker'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import NuevoIncidente from '../../components/incidentes/NuevoIncidente'
import ImgViewer from '../../components/util/ImgViewer'
import PDFViewer from '../../util/plantillas/PDFViewer'
import AdjuntarArchivos from '../../components/incidentes/AdjuntarArchivos'
import SearchDataTable from '../../components/util/SearchDataTable'
import moment from 'moment'

export default {
    data() {
        return {
            id: this.$route.params.id,
            personal_id: 0,
            moment: moment,
            panel: 0,
            load: false,
            load_comentario: false,
            dialog: false,
            dialog_img: false,
            dialog_pdf: false,
            dialog_archivos: false,
            comentario: '',
            nombre_ver: '',
            titulo: '',
            search: '',
            pdf: null,
            img: null,
            selected: [],
            archivos: [],
            archivos_adjuntos: [],
            comentarios: [],
            incidentes: [],
            headers: [
                { text: 'Número', value: 'id', align: 'center' },
                { text: 'Asunto', value: 'asunto', sortable: false },
                { text: 'Estado', value: 'estado_nombre' },
                { text: 'Fecha creación', value: 'fecha_creacion', formatter: format_date },
                { text: 'Fecha finalización', value: 'fecha_finalizacion', formatter: format_date },
                { text: 'Usuario finalización', value: 'usuario_finalizacion', sortable: false }
            ],
            filtro: {
                id: '',
                asunto: '',
                estado: '',
                personal_id: ''
            },

            estados: [],
            nombreUs: ''
        }
    },
    async created() {
        this.$store.state.loading = true
        await this.$store.dispatch('get_jarvis_token')

        await this.$store.dispatch('incidentes/getEstados').then(res => {
            if (res.exito) {
                this.estados = res.data
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'error'
                })
            }
        }
        )

        await this.$store.dispatch('incidentes/getPersonalId').then(res => {
            if (res.exito) {
                this.personal_id = res.data.Personal_id
                this.filtro.personal_id = this.personal_id
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'error'
                })
            }
        })

        await this.$store.dispatch('incidentes/getNombreUsuario',
            {
                personal_id: this.personal_id
            }
        ).then(res => {
            if (res.exito) {
                this.nombreUs = res.data
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: res.message,
                    color: 'error'
                })
            }
        })


        if (this.id) {
            this.filtro.id = this.id
        }
        await this.buscar()
        this.$store.state.loading = false
    },
    computed: {
        ...mapState('incidentes', ['cargaInc'])
    },
    components: {
        ImgViewer,
        PDFViewer,
        BtnFiltro,
        FechaPicker,
        BtnConfirmar,
        NuevoIncidente,
        AdjuntarArchivos,
        SearchDataTable
    },
    watch: {
        async selected(newVal, oldVal) {
            this.archivos = []
            this.comentarios = []
            this.archivos_adjuntos = []
            this.comentario = ''
            if (this.selected[0]) {
                if (this.selected[0].archivos) {
                    this.archivos = this.selected[0].archivos
                }
                if (this.selected[0].comentarios) {
                    this.comentarios = this.selected[0].comentarios
                }
            }
            // obtiene las imagenes del registro seleccionado solo cuando lo selecciona por primera vez
            if (newVal.length == 1 && ((oldVal.length == 0) || (oldVal[0].id != newVal[0].id))) {
                const id = await JSON.parse(JSON.stringify(this.selected[0].id))
                let selected = this.incidentes.find(c => c.id == id)
                if (!selected.archivos) {
                    this.$store.state.loading = true
                    this.load_comentario = true
                    await this.$store.dispatch('incidentes/getArchivos', { id: selected.id })
                        .then((res) => {
                            selected.archivos = res.data
                            this.archivos = selected.archivos
                        })
                        .catch(error => {
                            this.$store.dispatch('show_snackbar', {
                                text: error.message,
                                color: 'error'
                            })
                        })
                    this.$store.state.loading = false
                    this.load_comentario = false
                }
                if (!selected.comentarios) {
                    this.load_comentario = true
                    await this.$store.dispatch('incidentes/getComentarios', { id: selected.id })
                        .then((res) => {
                            selected.comentarios = res.data
                            this.comentarios = selected.comentarios
                        })
                        .catch(error => {
                            this.$store.dispatch('show_snackbar', {
                                text: error.message,
                                color: 'error'
                            })
                        })
                    this.load_comentario = false
                }
            }
        }
    },
    methods: {
        async buscar() {
            this.incidentes = []
            this.selected = []
            this.search = ''
            this.load = true
            await this.$store.dispatch('incidentes/getIncidentes', this.filtro)
                .then((res) => {
                    if (res.exito) {
                        this.incidentes = res.data
                    }
                    else {
                        this.$store.dispatch('show_snackbar', {
                            text: res.message,
                            color: 'error'
                        })
                    }
                })
            this.load = false
        },
        async enviar_comentario() {
            if (!this.comentario.trim()) {
                return this.$store.dispatch('show_snackbar', {
                    text: 'No puede enviar un comentario vacio',
                    color: 'orange'
                })
            }
            // valido si en algun lado del comentario puso adjunto para avisarle por si no adjuntó nada
            const tiene_adjunto = this.comentario.toLowerCase().indexOf('adjunto') >= 0
            const tiene_archivo = this.comentario.toLowerCase().indexOf('archivo') >= 0
            if ((tiene_adjunto || tiene_archivo) && this.archivos_adjuntos.length == 0) {
                let ups_me_olvide_el_archivo = false
                let abrir_modal = false
                await this.$swal.fire({
                    icon: 'warning',
                    html: `Se encontró la palabra <strong>${tiene_archivo ? 'archivo' : ''} ${tiene_adjunto ? 'adjunto' : ''}</strong> en el comentario pero no se
            seleccionó ningún archivo.<div style="font-size: 20px; margin-top: 12px"><strong>¿Desea enviar el comentario de todas formas?</strong></div>`,
                    confirmButtonText: 'Sí, enviar de todas formas',
                    denyButtonText: 'No, seleccionar archivos',
                    denyButtonColor: '#3085d6',
                    showDenyButton: true,
                    focusDeny: true
                })
                    .then((res) => {
                        abrir_modal = res.isDenied
                        // si pone que no o hace click fuera del modal no hace nada
                        if (!res.isConfirmed) {
                            ups_me_olvide_el_archivo = true
                        }
                    })
                if (abrir_modal) this.dialog_archivos = true
                if (ups_me_olvide_el_archivo) return
            }
            this.$store.state.loading = true
            let archivos = []
            // convierto los archivos en base64
            for (const file of this.archivos_adjuntos) {
                await getBase64(file).then(data => {
                    archivos.push({
                        file: data.split(',')[1],
                        name: file.name
                    })
                })
            }
            await this.$store.dispatch('incidentes/comentario', {
                id: this.selected[0].id,
                comentario: this.comentario,
                archivos: archivos,
                personal_id: this.personal_id
            })
                .then((res) => {

                    this.$swal.fire({
                        icon: res.errores ? 'warning' : 'success',
                        title: res.message,
                        html: res.errores
                    })
                    // agrego el comentario
                    this.comentarios.push({
                        id: res.id,
                        comentario: this.comentario,
                        usuario: this.nombreUs.toUpperCase(),
                        fecha: moment(new Date()).format('DD/MM/YY HH:mm'),
                        carga: this.cargaInc ? 1 : null
                    })
                    // agrego los archivos que subieron al array general de archivos
                    for (const archivo of res.archivos) {
                        this.archivos.push(archivo)
                    }
                    this.comentario = ''
                    this.archivos_adjuntos = []
                })
                .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                        text: error.message,
                        color: 'error'
                    })
                })
            this.$store.state.loading = false
        },
        ver_archivo(archivo, ruta) {
            const tipo = this.get_atributo_x_extension(archivo.extension, 'tipo')
            this.nombre_ver = archivo.nombre
            if (tipo == 'IMG') {
                this.img = `data:${this.get_atributo_x_extension(archivo.extension, 'mime')};base64,${archivo.file}`
                this.dialog_img = true
            }
            else if (tipo == 'PDF') {
                this.pdf = `data:application/pdf;base64,${archivo.file}`
                this.dialog_pdf = true
            }
            else {
                const routeData = this.$router.resolve({ path: `/descargarArchivo?path=/incidentes/${this.selected[0].id}${ruta}/${archivo.nombre}` })
                window.open(routeData.href, '_blank')
            }
        },
        rowClick(item, row) {
            let selectState = (row.isSelected) ? false : true
            row.select(selectState)
            if (!row.isSelected) this.$vuetify.goTo('#detalle')
        },
        // obtiene un atributo especifico del array tipos_archivos en base a la extension del archivo indicado
        get_atributo_x_extension(extension, atributo) {
            return tipos_archivos.find(a => a.extension == extension) ? tipos_archivos.find(a => a.extension == extension)[atributo] : ''
        },
        limpiar() {
            this.search = ''
            this.filtro = {
                numero: '',
                asunto: '',
                estado: null
            }
        }
    }
}
</script>